import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "*",
    redirect: "index",
  },
  {
    path: "/index",
    name: "index",
    component: (resolve) => require(["@/view/index.vue"], resolve),
    meta: {
        title: "",
    }
  },
];

let router = new VueRouter({
    mode: "hash",
    routes,
});

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router;
