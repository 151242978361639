<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",

  mounted() {
    
  },

  methods: {
    
  },
};
// document.domain = '192.168.0.111:8080'
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #E5E5E5;
  letter-spacing: 0.1em;
}

body {
  margin: 0 !important;
  background-color: #090C18;
}

body,
button {
  font-family: LemonMilk;
}

@font-face {
  font-family: LemonMilk;
  src: url("./assets/font/LemonMilk.otf");
}

@font-face {
  font-family: BeVietnam-Bold;
  src: url("./assets/font/BeVietnam-Bold.ttf");
}
</style>
